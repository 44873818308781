import { AuthContext } from "../../App";
import { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { Dialog } from "@mui/material";
import blackOreoFull from "../../assets/black oreo full.png";
import whiteOreoFull from "../../assets/white oreo full.png";
import rectangle516 from "../../assets/Rectangle 516.png";
import shuimo from "../../assets/shuimo1.png";
import headerbg from "../../assets/description-header.png";
import bottombg from "../../assets/description-bottom.png";
import nicknamebg1 from "../../assets/nicknamebg.png";
import nicknamebg2 from "../../assets/nicknameShuimo.png";
import closeButton from "../../assets/description-close.png";
// import bamboo from "../../assets/bamboo-detail.png";
import footerBackground from "../../assets/footer-bg.png";
import { serverAddress } from "../../App";
import { useHistory } from "react-router";
import downloadPoster from "../../assets/download-poster.png";
import onchainView from "../../assets/onchain-review.png";
import "./index.css";

export default function NFTDetail({ id }) {
  const { state, dispatch } = useContext(AuthContext);
  const [detail, setDetail] = useState({});
  const params = useParams();
  document.title = "我的NFO收藏馆";

  let tokenId;
  if (id) {
    tokenId = id;
  } else {
    tokenId = params.id;
  }
  useEffect(() => {
    fetch(serverAddress + "/nfts/e2/details/" + tokenId, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => {
        setDetail({
          contract: getSubStr(res.data.contract),
          owner: getSubStr(res.data.owner),
          nickname: res.data.nickname,
          txhash: res.data.txhash,
          updatetime: res.data.updatetime,
        });
      })
      .catch((e) => {
        return;
      });
  }, []);
  const [myTokenId, setMyTokenId] = useState();
  useEffect(() => {
    if (state.isLogin) {
      let headers = new Headers();
      headers.append("accountId", state.accountId);
      headers.append("accessToken", state.accessToken);
      fetch(serverAddress + "/tokenid", {
        method: "GET",
        headers: headers,
      })
        .then((res) => res.json())
        .then((res) => {
          setMyTokenId(res.data.tokenId);
        })
        .catch((e) => {
          return;
        });
    }
  }, [state.isLogin]);
  // const [mai, setMai] = useState();
  // useEffect(() => {
  //   if (state.accountId && production) {
  //     const mai = new Mai("mdlz-production", state.accountId, {
  //       oauthApiBaseUrl: "https://scrm-oauth.emdlz.com.cn", // OAuth 网络请求 Base URL
  //       consumerApiBaseUrl: "https://scrm-consumer-api.emdlz.com.cn", // Consumer API 网络请求 Base URL
  //       businessApiBaseUrl: "https://scrm-business-api.emdlz.com.cn", // Staff API 网络请求 Base URL
  //       cdnBaseUrl: "https://scrm-statics.emdlz.com.cn", // CDN 资源 Base URL
  //       appName: "oreo-nft", // optional 应用名称，将拼接到日志的 category 中
  //       appVersion: "0.0.1", // optional 应用版本
  //     });
  //     setMai(mai);
  //   } else if (state.accountId && !production) {
  //     const mai = new Mai("staging", state.accountId);
  //     setMai(mai);
  //   }
  // }, []);
  const history = useHistory();
  const getPoster = () => {
    // TODO: 上报数据
    try {
      state.mai.memberEvent.page.logOperate({
        type: "click",
        content: "NFT海报生成-页面-页面浏览-点击元宇宙身份生成",
        pageContent: "NFT海报生成-页面-页面浏览",
        utmCampaign: "nft",
      });
    } catch (error) {
      console.log(error);
    }
    history.push("/poster");
  };
  const explore = () => {
    try {
      state.mai.memberEvent.page.logOperate({
        type: "click",
        content: "NFT海报生成-页面-页面浏览-点击链上查看",
        pageContent: "NFT海报生成-页面-页面浏览",
        utmCampaign: "nft",
      });
    } catch (error) {
      console.log(error);
    }
    window.location.href =
      "https://confluxscan.net/transaction/" + detail.txhash;
  };
  const getSubStr = (str) => {
    var subStr1 = str.substr(0, 7);
    var subStr2 = str.substr(str.length - 29, 29);
    var subStr = subStr1 + "..." + subStr2;
    return subStr;
  };
  const isWhite = (id) => {
    if (id > 0 && id < 6) {
      return true;
    }
  };
  const [open, setOpen] = useState(false);
  const openDescription = () => {
    try {
      state.mai.memberEvent.page.logOperate({
        type: "click",
        content: "NFT海报生成-页面-页面浏览-点击活动说明",
        pageContent: "NFT海报生成-页面-页面浏览",
        utmCampaign: "nft",
      });
    } catch (error) {
      console.log(error);
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [isNicknameOpen, setNicknameOpen] = useState(false);
  const openNickname = () => {
    try {
      state.mai.memberEvent.page.logOperate({
        type: "click",
        content: "NFT海报生成-页面-页面浏览-点击同步昵称",
        pageContent: "NFT海报生成-页面-页面浏览",
        utmCampaign: "nft",
      });
    } catch (error) {
      console.log(error);
    }
    setNicknameOpen(true);
  };
  const closeNickname = () => {
    setNicknameOpen(false);
  };
  const getNickname = () => {
    let headers = new Headers();
    headers.append("accountId", state.accountId);
    headers.append("accessToken", state.accessToken);
    fetch(serverAddress + "/nickname", {
      method: "POST",
      headers: headers,
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((res) => {
          if (res.returnCode === "20000") {
            setDetail({
              ...detail,
              nickname: res.data.nickname,
            });
            setNicknameOpen(false);
          }
        });
      }
    });
  };
  const clickMetaverse = () => {
    try {
      state.mai.memberEvent.page.logOperate({
        type: "click",
        content: "NFT海报生成-页面-页面浏览-点击元宇宙展览馆",
        pageContent: "NFT海报生成-页面-页面浏览",
        utmCampaign: "nft",
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      detail.nickname &&
      detail.owner &&
      detail.nickname !== detail.owner.substring(detail.owner.length - 8)
    ) {
      dispatch({
        type: "NICKNAME",
        payload: {
          sync: true,
        },
      });
    }
  }, [detail.nickname, detail.owner]);

  return (
    <div
      className="flex flex-col items-center relative"
      style={{ minHeight: "100vh", overflow: "hidden" }}
    >
      <div className="mt-6 font-semibold text-sm z-30" style={{color: "#474747"}}>该藏品已开放提取到数字钱包，需前往 cocafe 指定<br />提取 NFO 站点「星际岛」领取，<a className="underline" href="https://galaxy.cocafe.co/login" style={{color: "#C79E53"}}>点击前往</a></div>
      <div className="absolute w-full z-0">
        <img src={rectangle516} style={{ height: "17rem" }}></img>
      </div>
      <div className="absolute w-full z-10 -top-16">
        <img className="w-full" src={shuimo}></img>
      </div>
      {/* <div className="absolute left-0 z-10" style={{ marginTop: "15rem" }}>
        <img src={bamboo} alt="竹子" style={{ height: "10rem" }}></img>
      </div> */}
      <div className="absolute w-full -bottom-24 z-0">
        <img className="w-full" src={footerBackground}></img>
      </div>
      <div className="z-20 flex justify-between w-full mt-20 mb-1 absolute">
        <Link
          onClick={clickMetaverse}
          to="/home"
          className="flex justify-center items-center nfo-gallery text-sm"
        >
          <div>元宇宙展览馆</div>
        </Link>
        <div
          onClick={openDescription}
          className="flex justify-center items-center activity-description text-sm"
        >
          <div>活动说明</div>
        </div>
      </div>
      <div className="z-20" style={{ marginTop: "8.75rem" }}>
        <div className="flex flex-col items-center">
          <div
            className="relative flex detail-card"
            style={{ width: "16.875rem", height: "9.8125rem" }}
          >
            <img
              className="absolute"
              src={
                "https://elements.cocafe.co/nfo/image/big-gif/" +
                tokenId +
                ".gif"
              }
              style={{
                width: "8.875rem",
                right: "0.38rem",
                top: "0.45rem",
              }}
            />
            <img
              className="z-30"
              src={isWhite(tokenId) ? whiteOreoFull : blackOreoFull}
              style={{ width: "16.875rem", height: "9.8125rem" }}
            ></img>
          </div>
          <div
            className="relative flex justify-center mt-14 detail-card-border z-30"
            style={{ maxWidth: "20.4375rem" }}
          >
            <div className="absolute -top-8">
              <div className="relative text-xs" style={{ color: "#847368" }}>
                我的NFO专属编号
              </div>
              <div
                className="relative glass-tty -top-1"
                style={{ fontSize: "2.6875rem", letterSpacing: "-1px" }}
              >
                #{tokenId}
              </div>
            </div>
            <div className="my-10 mx-7">
              <div style={{ minWidth: "17.0625rem" }}>
                <div>
                  <div className="flex text-sm">
                    <div style={{ color: "#847368" }}>智能合约</div>
                  </div>
                  <div
                    className="text-left text-xs mt-2"
                    style={{ color: "#474747" }}
                  >
                    {detail?.contract}
                  </div>
                </div>
                <div className="flex justify-between items-center mt-4">
                  <div>
                    <div className="flex text-sm">
                      <div style={{ color: "#847368" }}>收藏者</div>
                    </div>
                    {/* TODO: 获取昵称 */}
                    <div
                      className="text-left text-xs mt-2"
                      style={{ color: "#474747" }}
                    >
                      {detail?.nickname}
                    </div>
                  </div>
                  <div>
                    {myTokenId === parseInt(tokenId) ? (
                      <button
                        onClick={openNickname}
                        disabled={state.sync}
                        className="text-sm sync-nickname"
                      >
                        同步昵称
                      </button>
                    ) : null}
                  </div>
                </div>
                <div className="mt-4">
                  <div className="flex text-sm">
                    <div style={{ color: "#847368" }}>
                      我的专属区块链身份凭证
                    </div>
                  </div>
                  <div
                    className="text-left text-xs mt-2"
                    style={{ color: "#474747" }}
                  >
                    {detail?.owner}
                  </div>
                </div>
                <div className="mt-4">
                  <div className="flex text-sm">
                    <div style={{ color: "#847368" }}>认证时间</div>
                  </div>
                  <div
                    className="text-left text-xs mt-2"
                    style={{ color: "#474747" }}
                  >
                    {detail?.updatetime}
                  </div>
                </div>
                <div
                  className="my-2"
                  style={{ border: "0.5px solid #D5D8DC" }}
                ></div>
                <div
                  className="flex text-justify text-xs"
                  style={{ lineHeight: "22px", color: "#474747" }}
                >
                  NFO是生成式对抗网络算法(GAN)在奥利奥饼干上的动态水墨画作，每一块数字饼干都是独一无二的。它是铸造在区块链上的数字作品，区块数据记录了拥有者对于作品的主权，主权归属关系无法被篡改。
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="flex text-xs relative z-40"
          style={{ color: "#847368", height: "3.875rem" }}
        >
          <div className="absolute flex w-full -top-4">
            {myTokenId === parseInt(tokenId) ? (
              <div className="flex flex-col items-center w-1/2">
                <div
                  onClick={getPoster}
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "3.5rem",
                    height: "3.5rem",
                  }}
                >
                  <img
                    src={downloadPoster}
                    style={{
                      width: "2.875rem",
                      height: "2.875rem",
                      zIndex: "2",
                    }}
                  ></img>
                  <div
                    style={{
                      width: "3.5rem",
                      height: "3.5rem",
                      borderRadius: "3.5rem",
                      border: "20px solid #F4EFC3",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      animation: "blink 1.5s linear infinite",
                    }}
                  ></div>
                </div>
                <div className="mt-2">元宇宙身份生成</div>
              </div>
            ) : (
              <div className="w-1/4"></div>
            )}
            <div className="flex flex-col items-center w-1/2">
              <div
                onClick={explore}
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "3.5rem",
                  height: "3.5rem",
                }}
              >
                <img
                  src={onchainView}
                  style={{ width: "46px", height: "46px" }}
                ></img>
              </div>
              <div className="mt-2">链上查看</div>
            </div>
          </div>
        </div>
        <div className="text-xs mt-4 mb-5" style={{ color: "#6D6D6D" }}>
          「数字藏品为非卖品。不可转让不可售卖，仅供观赏和收藏」
        </div>
      </div>
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            position: "relative",
            borderRadius: "8px",
            backgroundImage: "url(" + bottombg + ")",
            backgroundSize: "cover",
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <img className="absolute w-full z-10 top-0" src={headerbg} />
        <div className="relative z-20 m-10">
          <img
            className="absolute -top-6 -left-6"
            src={closeButton}
            onClick={handleClose}
          />
          <div className="text-center mb-4" style={{ color: "#A6907B" }}>
            活动说明
          </div>
          <div className="text-xs activity-content">
            <div>
              <p>活动名称：数字奥利奥饼干(NFO)领取活动</p>

              <p>领取时间：2021年10月29日 20:00:00 -2021年11月5日 23:59:59</p>

              <p>
                什么是NFO：NFO（非同质化奥利奥数字饼干）是基于区块链技术，由智能合约铸造的奥利奥定制版数字藏品。由国内著名数字资产创作机构cocafe咖菲科技与AI艺术家夏语冰携手创作，并铸造在高性能区块链Conflux上。
              </p>
            </div>
            <div>
              <p className="font-bold">【领取NFO数字奥利奥饼干细则】</p>

              <p>
                1.
                适用对象：NFO发行站点cocafe咖菲科技向奥利奥小程序端中奖用户发放NFO数字饼干，限量5000份。每位用户（根据小程序端的open
                ID判断）最多仅可拥有一块NFO数字饼干。
              </p>

              <p>
                2.
                领取方式：每一位中奖用户需要在cocafe站点验证并授权绑定手机号作为领取奖品以及提取NFO数字饼干的唯一凭证。中奖用户的手机号请务必妥善保管。因个人原因泄露、遗失、转号而导致NFO数字饼干遗失或被盗的后果由该用户自行承担。
              </p>

              <p>
                3.
                如发现用户恶意利用多个手机号或者其他不正当行为获取NFO数字饼干，cocafe将有权取消该用户的领取资格和领取的奖品，所引发的一切法律责任和后果由该用户承担。
              </p>

              <p>
                4.
                每一块NFO都是独一无二的非卖品，无市场价格。NFO数字饼干仅可用于您的个人收藏，学习，研究，欣赏和展示之目的，不可转赠、不可售卖，或以其它任何形式进行转让，亦不可用于任何商业行为，并且严格禁止炒作、场外交易、欺诈等任何非法用途。
              </p>

              <p>
                5. 中奖用户(ID)
                在本站点领取NFO数字饼干后，可通过两种途径查看所持有的数字饼干奖品：
                a) 路径1：【奥利奥玩心小宇宙商城】小程序首页- 个人中心-
                积分下按钮- 我的NFO收藏馆。
                该查看路径会保留一年，至2022年11月5日23：59：59关闭。 b)
                路径2：通过conflux浏览器（地址：confluxscan.net）浏览NFO的智能合约地址来查看所持有的NFO。
                未来cocafe会提供更多的平台展示途径，具体消息请关注本站点的更新信息。
              </p>

              <p>
                6.
                中奖用户可在本次领取活动结束日2021/11/5后的100天之后，将已领取的奖品提取至自己的区块链钱包。具体提取时间段为：2022/02/14
                00:00 - 2022/12/31 23:59:59。用户需在“奥利奥玩心小宇宙”小程序中，前往 cocafe 指定提取 NFO 站点「星际岛」galaxy.cocafe.co 并依据页面提示步骤操作领取。
                每一位用户只有一次提取机会。用户不得对所持有的NFO数字饼干进行转赠、售卖、或以其它任何形式进行转让。
                用户在提取数字饼干后需妥善保管自己的数字藏品和自己的区块链钱包地址。提取后发生的一切遗失、失窃、无法找回等情形，均由用户自行承担。如果中奖用户不提取NFO至自己的个人钱包，用户依旧可将NFO保管在本站点。
                关于如何创建conflux区块链钱包和NFO提取,
                具体请根据conflux网站（地址：
                <a href="https://portal.confluxnetwork.org/">
                  https://portal.confluxnetwork.org/
                </a>
                ）公布的指导操作。
              </p>

              <p>7. 请再次仔细阅读《用户使用协议和免责声明》，文档见下方。</p>

              <p>
                8.
                在法律允许的范围内，以上说明的最终解释权归cocafe所有。cocafe保留任何对针对用户使用本站点服务细则的修改，更新和迭代。具体请见cocafe在本站点的公示。
              </p>
            </div>
            <div>
              <p className="font-bold">【NFO数字奥利奥饼干藏品介绍】</p>
              <p>
                根据奥利奥品牌方——亿滋中国的授权及委托，NFO由国内著名数字资产创作机构cocafe咖菲科技与AI艺术家夏语冰携手创作，并铸造在高性能区块链树图链上。
              </p>
              <p>
                cocafe咖菲科技(https://cocafe.co)是中国领先的NFT数字藏品创作发行机构，拥有丰富设计资源和海内外签约艺术家、专业的区块链产品与技术团队和成熟的NFT发行应用经验，涵盖从创意制作到发行销售的完整生态。cocafe与中国嘉德、麦当劳、海文交等知名品牌创造了多个行业标杆案例，并与文娱、消费、汽车、零售等行业领导者密切合作，
                共同赢得今天和未来的用户。
              </p>
              <p>
                夏语冰（AI）源于全球领先AI小冰，师从中央美术学院实验艺术研究院院长邱志杰教授。2019年央美研究生毕业，举办个展《或然世界》，并受邀参展“科技艺术界的奥斯卡”林茨电子艺术节。2021年，《山水精神》作品集在亚洲数字艺术展中展出。
              </p>
              <p>
                树图链采用创新自主研发“树图”区块算法，拥有自主知识产权。树图区块链研究院在上海市政府的关心支持下，由多伦多大学教授龙凡博士创始发起，华人唯一图灵奖得主姚期智院士担任首席科学家，立足于Conflux区块链底层系统的研发而建立。
              </p>
            </div>
            <div>
              <p className="font-bold">
                【cocafe咖菲科技用户使用协议和免责声明】
              </p>
              <p>
                您确认，一旦您以在线点击确认或其他方式签署本协议，即视为您已阅读并同意本协议的所有条款，且知晓并理解本协议所有条款的含义及相应的法律后果，本协议即视为已于您在线点击确认或以其他方式签署本协议之日有效签署，对各方成立并生效。
              </p>
              <p>
                1.
                本站点为cocafe咖菲科技所有并进行维护。下文中的“我们”指cocafe咖菲科技；“亿滋”指亿滋食品企业管理（上海）有限公司。
              </p>
              <p>
                2.
                本次活动数字藏品（即NFO数字奥利奥饼干）的领取对象为年龄在18周岁以上的中国公民。如您在本站点领取数字藏品，即表示您已经知晓并符合了该领取标准。若您因未符合年龄标准而导致发生的任何违法法律法规行为，需要您自行承担法律责任，同时本站点保留取消您领取资格的权利。
              </p>
              <p>
                3.
                数字藏品的拥有者不得将数字藏品用于任何商业用途。您获得的数字藏品为非卖品，无市场价格。NFO数字饼干仅可用于您的个人收藏，学习，研究，欣赏和展示之目的，不可转赠、不可售卖，或以其它任何形式进行转让，亦不可用于任何商业行为，并且严格禁止炒作、场外交易、欺诈等任何非法用途。本站点仅为展示数字藏品，严格禁止将数字藏品进行炒作、场外交易、欺诈等任何非法用途。请远离数字藏品非理性炒作，防范欺诈风险。
              </p>
              <p>
                4.
                您需要验证并授权绑定手机号领取以及提取您的数字藏品或使用本站点服务。在您登陆本站点时，您同意我们获取您的手机号；如果您不授予前述同意，您将无法领取或提取数字藏品，并可能无法正常使用本站点的服务。我们将严格遵守相关法律法规与监管要求，依法使用您的信息，并对您的信息保密。
              </p>
              <p>
                5. 在您于本站点领取数字藏品后 至
                您提取该数字藏品至自己个人区块链钱包之前（具体提取时间段为：2022/02/14
                00:00- 2022/12/31 23:59:59）
                ，手机号是您在本站点绑定账号以及在奥利奥微信小程序查看NFO数字藏品的唯一凭证。
                一个奥利奥小程序ID对应一个手机号（id）
                ，并对应唯一一个NFO数字藏品，
                请务必妥善保管好您的手机号。如发现有任何恶意利用多个手机号或者其他不正当行为获取NFO数字藏品的行为，cocafe有权取消该用户的领取资格和NFO数字藏品。
              </p>
              <p>
                6.
                当您将数字藏品从本cocafe站点提取到个人的区块链钱包后，您需妥善保管自己的数字藏品和自己的区块链钱包地址。提取完成后发生的一切遗失、失窃、无法找回等情形，均由您自行承担。如有使用或提取数字藏品疑问的，您可以通过
                nft@cocafe.co 咨询cocafe咖菲科技。
                <br />
                *关于钱包创建和提取方法，请以conflux网站
                <a href="https://portal.confluxnetwork.org/">
                  (https://portal.confluxnetwork.org/)
                </a>
                说明及本站点后续的公告为准。
                <br />
                cocafe咖菲科技未来在本站点的服务功能会升级和迭代，具体以cocafe咖菲科技公布为准。
              </p>
              <p>
                7.
                基于区块链技术的特性，数字藏品具有唯一、不可篡改的特点，数字藏品中的数字内容，铸造时间等信息无法篡改。在您获得数字藏品后，您与数字藏品的所有权等权利关系将通过智能合约记录在区块数据中。
              </p>
              <p>
                8.
                您理解并同意，您领取NFO数字藏品后，只拥有该数字藏品本身的物权，不拥有该数字藏品对应的原艺术品的所有权及包括著作权在内的各项知识产权，亦不拥有该数字藏品的知识产权。
              </p>
              <p>
                9.
                您理解并同意，基于维护网络秩序和安全，如我们发现您存在违法违规或违反本协议约定情形的，我们有权采取同时采取一项或多项措施，包括但不限于：
                A) 要求您限期纠正违规或违约行为； B) 屏蔽或限制您使用数字藏品；
                C) 对违法违规信息进行删除或屏蔽 D)
                停或终止向您提供部分或全部服务； E) 其他合理、合法的措施。
                我们因任何原因未采取上述任何措施，不代表我们放弃未来采取该等措施的权利。如您的行为使我们或亿滋遭受损失的，我们还有权要求您赔偿我们或亿滋的全部损失，包括商誉损失、赔偿金、律师费、诉讼费等。
              </p>
              <p>
                10.
                您理解并同意，在您获得数字藏品和使用本服务的过程中，可能会遇到不可抗力等风险因素。如出现下列任一情况致使我们无法履行本协议约定的，我们将努力在第一时间与相关单位配合，及时进行修复，但是由此给您或第三方造成的损失，我们不承担责任：
                A) 在与本服务相关的系统停机维护或升级期间； B)
                因自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、法律法规政策变化和政府行为等不可抗力原因；
                C) 您操作不当或通过非我们授权或认可的方式使用本服务； D)
                因病毒、木马、恶意程序攻击网络拥堵、系统不稳定、系统或设备故障、通讯故障电力故障、电信设备故障、第三方服务瑕疵等原因。
              </p>
              <p>
                11.
                您理解并同意，鉴于网络服务的特殊性，我们对本服务的持续提供可能受到多种因素的影响，如出现技术升级、服务体系升级、或因经营策略调整或配合国家重大技术、法规政策等变化。我们可能随时终止、中断提供服务或变更所提供服务的形式、规格或其他方面，而您不会因此追究我们的责任。
              </p>
              <p>
                12.
                除上文已经特别指出的以外，发行站点上的所有内容，包括但不限于文字、图片、档案、资讯、资料、平台架构、平台画面的安排、网页设计等，均由发行方/创作者/合作方/依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。非经我们书面同意，您不得擅自使用、修改、反向编译，复制，公开传播、改变、散布、发行或公开发表、转让、许可、商业利用本站点的相关软件、内容以及我们发行方和合作方享有的知识产权。否则我们有权立即终止本协议，您应对您的违约或侵权行为给我们或其他权利人造成的损失承担相应赔偿责任。
              </p>
              <p>
                13.
                为进一步改善用户体验，我们将联合我们的关联方和/或合作方持续更新服务，包括但不限于版本升级、功能升级、技术升级、新增、更新或调整服务内容和功能，本协议可能会随之更新。我们也会根据国家法律法规的变化要求，更新本协议的相关条款。更新的内容将于协议更新公告公布的生效之日起生效。若您不接受变更后的服务内容或功能，或不同意更新后的协议内容，您应立即停止使用相关服务。若您继续使用本协议相关服务的，即视为您已同意我们作出的变更，并自愿接受变更后的协议内容约束。
              </p>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            position: "relative",
            borderRadius: "8px",
          },
        }}
        open={isNicknameOpen}
        onClose={closeNickname}
      >
        <img className="absolute w-full z-0 bottom-0" src={nicknamebg1} />
        <img className="absolute w-full z-10 top-0" src={nicknamebg2} />
        <div className="relative z-20 m-10">
          <div className="text-justify" style={{ color: "#A6907B" }}>
            您正在通过小程序获取微信昵称，请确认授权。
          </div>
          <div className="flex mt-11">
            <div className="flex justify-center w-full">
              <button onClick={closeNickname} className="nickname cancel">
                取消
              </button>
            </div>
            <div className="flex justify-center w-full">
              {/* TODO: 同步昵称 */}
              <button onClick={getNickname} className="nickname confirm">
                确定
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
