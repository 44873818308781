import { useEffect, useState, useContext } from "react";
import { Dialog } from "@mui/material";
import { Link } from "react-router-dom";
import { AuthContext } from "../../App";
import { serverAddress } from "../../App";
import { useHistory } from "react-router";
import rectangle516 from "../../assets/Rectangle 516.png";
import shuimo from "../../assets/shuimo.png";
import nicknamebg1 from "../../assets/nicknamebg.png";
import nicknamebg2 from "../../assets/nicknameShuimo.png";
// import bamboo from "../../assets/bamboo.png";
import ellipse13 from "../../assets/Ellipse13.png";
import right from "../../assets/right.png";

import "./index.css";

import banner from "../../assets/banner.png";
import tapHand from "../../assets/tap-hand.png";
import left from "../../assets/left.png";
import hill from "../../assets/hill.png";
import blackBiscuit from "../../assets/biscuit.png";
import whiteBiscuit from "../../assets/biscuit-white.png";

export default function Home() {
  const { state, dispatch } = useContext(AuthContext);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const history = useHistory();
  document.title = "元宇宙NFO展览馆";

  useEffect(() => {
    fetch(serverAddress + "/nfts/e2/total", {
      method: "GET",
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((res) => {
            if (res.returnCode === "20000") {
              if (res.data.total !== 0) {
                setTotalPage(Math.ceil(res.data.total / 6));
              }
            } else {
              return false;
            }
          });
        } else {
          return false;
        }
      })
      .catch((e) => {
        return false;
      });
  }, [state.isLogin]);

  const [NFTList, setNFTList] = useState([]);

  useEffect(() => {
    fetch(serverAddress + "/nfts/e2/" + page + "/6", {
      method: "GET",
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((res) => {
            if (res.returnCode === "20000") {
              setNFTList(res.data.tokenId);
            } else {
              return false;
            }
          });
        } else {
          return false;
        }
      })
      .catch((e) => {
        return false;
      });
  }, [page]);

  // const [tokenId, setTokenId] = useState();
  // useEffect(() => {
  //   if (state.isLogin && state.isLucky) {
  //     let headers = new Headers();
  //     headers.append("accountId", state.accountId);
  //     headers.append("accessToken", state.accessToken);

  //     fetch(serverAddress + "/tokenid", {
  //       method: "GET",
  //       headers: headers,
  //     })
  //       .then((res) => {
  //         if (res.status === 200) {
  //           res.json().then((res) => {
  //             if (res.returnCode === 20000) {
  //               setTokenId(res.data.tokenId);
  //             } else {
  //               return null;
  //             }
  //           });
  //         } else {
  //           return null;
  //         }
  //       })
  //       .catch((e) => {
  //         return null;
  //       });
  //   }
  // }, [state.hasWithdrawn]);

  const prePage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const nextPage = () => {
    if (page < totalPage) {
      setPage(page + 1);
    }
  };

  const [luckyOpen, setLuckyOpen] = useState(false);

  const clickMyNft = () => {
    if (state.isLucky && state.hasWithdrawn) {
      history.push("/nfo/" + state.tokenId);
    } else {
      setLuckyOpen(true);
    }
  };

  const closeLucky = () => {
    setLuckyOpen(false);
  };

  return (
    <div className="flex flex-col items-center bg-cover relative">
      <div className="absolute w-full z-0">
        <img className="w-full" src={shuimo}></img>
      </div>
      <div className="absolute w-full z-10">
        <img
          className="w-full"
          src={rectangle516}
          style={{ height: "17rem" }}
        ></img>
      </div>
      <div className="absolute w-full z-20 flex justify-center">
        <img src={ellipse13}></img>
      </div>
      <div className="absolute w-full bottom-0 z-0">
        <img className="w-full" src={hill}></img>
      </div>
      {/* <div className="absolute left-0 z-0" style={{ marginTop: "15rem" }}>
        <img src={bamboo} alt="竹子" style={{ height: "10rem" }}></img>
      </div> */}
      <div className="mt-6 font-semibold text-sm z-30" style={{color: "#474747"}}>该藏品已开放提取到数字钱包，需前往 cocafe 指定<br />提取 NFO 站点「星际岛」领取，<a className="underline" href="https://galaxy.cocafe.co/login" style={{color: "#C79E53"}}>点击前往</a></div>
      <div className="z-30" style={{ maxWidth: "20rem", minHeight: "100vh" }}>
        <div className="flex flex-col justify-center items-center">
          <div
            className="mt-6 mb-5 flex justify-center"
            style={{ height: "1.75rem" }}
          >
            <div
              className="text-2xl"
              style={{
                color: "#A6907B",
                textShadow: "0px 2px 1px rgba(185, 161, 138, 0.2)",
              }}
            >
              <p>数字水墨国风“千里江山图”</p>
            </div>
          </div>
          <Link
            className="relative flex justify-center items-center"
            to="/scroll"
          >
            <div
              style={{
                position: "absolute",
                top: "0",
                width: "100%",
                height: "30px",
                background:
                  "linear-gradient(270deg, rgba(196, 196, 196, 0) -5%, rgba(149, 149, 149, 0.6) 48.7%, rgba(196, 196, 196, 0) 107.83%)",
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                bottom: "0",
                width: "100%",
                height: "30px",
                background:
                  "linear-gradient(270deg, rgba(196, 196, 196, 0) -5%, rgba(149, 149, 149, 0.6) 48.7%, rgba(196, 196, 196, 0) 107.83%)",
              }}
            ></div>
            <img className="absolute" src={tapHand} alt="tap"></img>
            <img className="z-10" src={banner} alt="卷轴"></img>
          </Link>
        </div>
        <div>
          <div className="flex h-10 w-full my-6 relative">
            <div className="flex justify-center items-center gallery-title absolute -left-8">
              <div style={{ color: "#A6907B" }}>NFO数字藏品</div>
            </div>
            <div className="w-1/2">
              <div
                onClick={clickMyNft}
                className="absolute right-0 text-sm flex justify-center items-center h-10"
              >
                <button className="my-nfo">
                  <p>我的NFO</p>
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className="grid grid-cols-2 relative">
              {NFTList.map((id, index) => {
                return (
                  <div key={index} className={"oreo-wrapper item" + index}>
                    <div
                      className={"oreo-container item" + index + " relative"}
                    >
                      <Link to={"/nfo/" + id}>
                        <div className="relative oreo-card flex justify-center items-center">
                          <img
                            className="absolute"
                            style={{ width: "111px", height: "111px" }}
                            src={
                              "https://elements.cocafe.co/nfo/image/small-gif/" +
                              id +
                              ".gif"
                            }
                          />
                          {id > 0 && id < 6 ? (
                            <img
                              className="z-40"
                              style={{ width: "125px", height: "125px" }}
                              src={whiteBiscuit}
                            />
                          ) : (
                            <img
                              className="z-40"
                              style={{ width: "125px", height: "125px" }}
                              src={blackBiscuit}
                            />
                          )}
                        </div>
                      </Link>
                      <div
                        className="flex justify-center glass-tty text-xl"
                        style={{
                          marginTop: "0.1rem",
                        }}
                      >
                        #{id}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex justify-center mt-8 mb-6">
            <div
              className="flex justify-between "
              style={{ width: "11.125rem" }}
            >
              <div onClick={prePage}>
                <img src={left} />
              </div>
              <div className="glass-tty text-xl flex items-center">
                {page}/{totalPage}
              </div>
              <div onClick={nextPage}>
                <img src={right}></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-xs mb-5 z-10" style={{ color: "#6D6D6D" }}>
        「数字藏品为非卖品。不可转让不可售卖，仅供观赏和收藏」
      </div>
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            position: "relative",
            borderRadius: "8px",
            overflow: "hidden",
          },
        }}
        open={luckyOpen}
        onClose={closeLucky}
      >
        <img className="absolute w-full z-0 bottom-0" src={nicknamebg1} />
        <img className="absolute w-full z-10 top-0" src={nicknamebg2} />
        <div className="relative z-20 mx-12 my-7">
          <div className="text-center" style={{ color: "#A6907B" }}>
            活动已结束
          </div>
          <div className="text-center mt-5">
            <div className="text-xs">您可尽情浏览本次玩心奥利奥数字藏品。</div>
            <div className="text-xs">期待下一次与您在元宇宙相遇！</div>
          </div>
          <div className="flex mt-3">
            <div className="flex justify-center w-full">
              <button onClick={closeLucky} className="nickname confirm">
                知道了
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
