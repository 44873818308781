import { createContext, useEffect, useReducer, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import NFTDetail from "./pages/NFTDetail";
import Scroll from "./pages/Scroll";
import Poster from "./pages/Poster";
import "./App.css";
import Claim from "./pages/Claim";
import Congrats from "./pages/Congrats";

export const AuthContext = createContext();
export const serverAddress = "/api/v1";
// export const serverAddress = "https://nfo-t.cocafe.co/api/v1";

const production = true;

const initialState = {
  isLogin: false,
  accountId: null,
  accessToken: null,
  isLucky: null,
  hasWithdrawn: null,
  nickName: null,
  isWhite: null,
  tokenId: null,
  isModalOpen: false,
  mai: {},
  sync: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      sessionStorage.setItem("accountId", action.payload.accountId);
      sessionStorage.setItem("accessToken", action.payload.accessToken);
      return {
        ...state,
        isLogin: action.payload.isLogin,
        accountId: action.payload.accountId,
        accessToken: action.payload.accessToken,
      };
    case "AUTH":
      return {
        ...state,
        isLucky: action.payload.isLucky,
        hasWithdrawn: action.payload.hasWithdrawn,
        nickName: action.payload.nickName,
        isWhite: action.payload.isWhite,
      };
    case "TOKENID":
      return {
        ...state,
        tokenId: action.payload.tokenId,
      };
    case "REGISTER":
      return {
        ...state,
        hasWithdrawn: action.payload.hasWithdrawn,
        tokenId: action.payload.tokenId,
      };
    case "LOGINMODAL":
      return {
        ...state,
        isModalOpen: action.payload.isModalOpen,
      };
    case "MAI":
      return {
        ...state,
        mai: action.payload.mai,
      };
    case "NICKNAME":
      return {
        ...state,
        sync: action.payload.sync,
      };
    default:
      return state;
  }
};

export default function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);

    if (params.get("accountId") && params.get("accesstoken")) {
      let idList = params.getAll("accountId");
      let tokenList = params.getAll("accesstoken");
      let id = idList[idList.length - 1];
      let token = tokenList[tokenList.length - 1];
      dispatch({
        type: "LOGIN",
        payload: {
          isLogin: true,
          accountId: id,
          accessToken: token,
        },
      });
    } else if (
      sessionStorage.getItem("accountId") &&
      sessionStorage.getItem("accessToken")
    ) {
      dispatch({
        type: "LOGIN",
        payload: {
          isLogin: true,
          accountId: sessionStorage.getItem("accountId"),
          accessToken: sessionStorage.getItem("accessToken"),
        },
      });
    }
  }, []);

  const [page, setPage] = useState();
  useEffect(() => {
    if (state.isLogin) {
      let headers = new Headers();
      headers.append("accountId", state.accountId);
      headers.append("accessToken", state.accessToken);
      fetch(serverAddress + "/auth", {
        method: "GET",
        headers: headers,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.returnCode === "20000") {
            dispatch({
              type: "AUTH",
              payload: {
                isLucky: res.data.lucky,
                hasWithdrawn: res.data.withdraw,
                nickName: res.data.nickname,
                isWhite: res.data.white,
              },
            });
            if (res.data.lucky && !res.data.withdraw) {
              setPage(<Claim />);
            } else if (!res.data.lucky) {
              setPage(<Home />);
            }
          }
        });
    }
  }, [state.isLogin]);

  useEffect(() => {
    let headers = new Headers();
    headers.append("accountId", state.accountId);
    headers.append("accessToken", state.accessToken);
    if (state.isLogin) {
      fetch(serverAddress + "/tokenid", {
        method: "GET",
        headers: headers,
      })
        .then((res) => {
          if (res.status === 200) {
            res.json().then((res) => {
              if (res.returnCode === "20000") {
                dispatch({
                  type: "TOKENID",
                  payload: {
                    tokenId: res.data.tokenId,
                  },
                });
              } else {
                return null;
              }
            });
          } else {
            return null;
          }
        })
        .catch((e) => {
          return null;
        });
    }
  }, [state.isLogin]);

  useEffect(() => {
    if (state.hasWithdrawn && state.tokenId) {
      setPage(<NFTDetail id={state.tokenId} />);
    }
  }, [state.hasWithdrawn, state.tokenId]);

  useEffect(() => {
    if (state.tokenId) {
      fetch(serverAddress + "/nfts/e2/details/" + state.tokenId, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.data.returnCode === "20000") {
            if (
              res.data.nickname !==
              res.data.owner.substring(res.data.owner.length - 8)
            ) {
              dispatch({
                type: "NICKNAME",
                payload: {
                  sync: true,
                },
              });
            }
          }
        })
        .catch((e) => {
          return;
        });
    }
  }, [state.tokenId]);

  useEffect(() => {
    if (state.accountId && production) {
      try {
        const mai = new Mai("mdlz-production", state.accountId, {
          oauthApiBaseUrl: "https://scrm-oauth.emdlz.com.cn",
          consumerApiBaseUrl: "https://scrm-consumer-api.emdlz.com.cn",
          businessApiBaseUrl: "https://scrm-business-api.emdlz.com.cn",
          cdnBaseUrl: "https://scrm-statics.emdlz.com.cn",
          appName: "oreo-nft",
          appVersion: "0.0.1",
        });
        const accessToken = state.accessToken;
        mai.member.signin({ accessToken });
        dispatch({
          type: "MAI",
          payload: {
            mai: mai,
          },
        });
      } catch (error) {
        console.log(error);
      }
    } else if (state.accountId && !production) {
      try {
        const mai = new Mai("staging", state.accountId);
        dispatch({
          type: "MAI",
          payload: {
            mai: mai,
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [state.accountId]);

  return (
    <div className="App">
      <AuthContext.Provider value={{ state, dispatch }}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Scroll />
            </Route>
            <Route exact path="/home">
              <Home />
            </Route>
            <Route exact path="/nfo/:id">
              <NFTDetail />
            </Route>
            <Route exact path="/claim">
              {page}
            </Route>
            <Route exact path="/scroll">
              <Scroll />
            </Route>
            <Route exact path="/poster">
              <Poster />
            </Route>
            <Route exact path="/congrats">
              <Congrats />
            </Route>
          </Switch>
        </Router>
      </AuthContext.Provider>
    </div>
  );
}
